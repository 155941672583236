import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      loadingTb: false,
      isActive: [],
      listStatker: [],
      listDataPaket: [],
      listVersi: [{
        idx_0 : [{}]
      }
        
      ],
      listsmFilter:[],
      tbData: [],
      pilihSatker:[],
      pilihVersiSemula:[],
      pilihVersiMenjadi:[],
      loadingVersi:false,
      idxCurrent:0,
      dataEdit:{
        id:null,
        keterangan:null,
        pergeseran:null,
        percepatan:null,
        luncuran:null
      }
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {

    this.$set(this, 'listVersi', [
      [{text:'Tidak Ada Data',value:0}]  
    ])

    // this.loadingVersi[0] = false
  
    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
    });

    this.G_GetRef('smFilter').then(data => {
      this.listsmFilter = data;
    });

  },
  components: {
    myTable
  },
  methods: {
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $('#sub_' + key).hide();
      } else {
        $('#sub_' + key).show();

      }
    },
    getVersi(idx) {
    
      // this.loadingVersi = true
      let filter = [];
      filter.push("kdsatker=" + this.pilihSatker[idx]);

      if (filter.length > 0) {
        filter = "&" + filter.join("&");
      }
      this.G_GetAny("rkakl/versi?saktiEventId=0" + filter).then((res) => {

       

        // this.loadingVersi = false
        
        let resData = res.data;

        let listData = [];
        let arrSub = {};

        resData.forEach((v) => {
          arrSub = {};

          arrSub["text"] = v.saktiVersionNm;
          arrSub["value"] = v.saktiVersionId;

          listData.push(arrSub);
        });

        this.$set(this, 'listVersi',idx_0, listData)
        

        // this.listVersi[idx] = listData;
        
        //  this.$nextTick(() => {

        //   this.listVersi[idx] = listData;
          console.log(this.listVersi);

        // })
      });

      // this.listVersi[idx] = listData;
      // console.log(this.listVersi[idx]);

     

      
    },
    store(){

      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);

      this.dataEdit.keterangan = formData.get('keterangan')

      this.G_PutAnyCustom('sakti/semula-menjadi/sm-item', this.dataEdit).then(res => {
        
        if (!res.status) {
          // this.getTable();
          this.$snotify.success('Berhasil Mengubah Data');
          this.$refs.modal.close()

          this.loadingTb = true

          let myForm = document.getElementById('formCari');
          let formData = new FormData(myForm);
          var object = {};
          formData.forEach((value, key) => object[key] = value);

          let filter = '';
        
          if (object.satker) {
            if (object.satker != 'all'){
              filter += '&kdsatker='+object.satker
            }
          }
        
          if (this.$refs.form.validate()) {
            this.$refs.btnAct.load(true)
            this.G_GetAny('sakti/semula-menjadi?sVersionId='+this.$refs.versiSemula.getValue()+'&mVersionId='+this.$refs.versiMenjadi.getValue()+''+filter).then(res => {


              if(res.status == 500){
                this.$refs.btnAct.load(false)
                this.loadingTb = false
                
              }else{
                this.listDataPaket = res.data
                this.$refs.btnAct.load(false)
                this.loadingTb = false
              }

            

            })
          }
          
        }else{
          this.$snotify.warning(res.data.message,'Terjadi Kesalahan');
        }

      })
      .finally(() => {
        this.$refs.modal.loadingEnd()
        
      });
    },
    editData(id,keterangan,pergeseran,percepatan,luncuran){

      this.$refs.modal.open("Edit Data", 600)

      this.dataEdit = {
        id:id,
        keterangan:keterangan,
        pergeseran:pergeseran,
        percepatan:percepatan,
        luncuran:luncuran
      }
      
    },
    reGenerate(){
      let myForm = document.getElementById('formCari');
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => object[key] = value);

      let filter = '';

      
      if (object.satker) {

        if (object.satker != 'all'){
          filter += '&kdsatker='+object.satker
        }
        this.generate(object.satker,"Generate Manual Data")
      }else{
        this.$snotify.warning('Pilih Satker Telebih Dahulu');
      }
      
    },
    addSatker(){
      this.idxCurrent++

      console.log(this.idxCurrent);

    },
    removeSatker(){
      this.idxCurrent--

      this.pilihSatker.pop();
      this.pilihVersiSemula.pop();
      this.pilihVersiMenjadi.pop();
    },
    cariData() {

      $('#tableShow').hide()
      // this.$refs.giat.selectAll()
      // let myForm = document.getElementById('formCari');
      // let formData = new FormData(myForm);


      // var object = {};
      // formData.forEach((value, key) => object[key] = value);

      // console.log(object);

      console.log(this.pilihSatker);
      console.log(this.pilihVersiSemula);
      console.log(this.pilihVersiMenjadi);

      // let filter = '';

    
      // if (object.satker) {
      //   if (object.satker != 'all'){
      //     filter += '&kdsatker='+object.satker
      //   }
      // }
  

      // if (this.$refs.form.validate()) {
      //   this.$refs.btnAct.load(true)
      //   this.G_GetAny('sakti/semula-menjadi?sVersionId='+this.$refs.versiSemula.getValue()+'&mVersionId='+this.$refs.versiMenjadi.getValue()+'&smFilterId='+this.$refs.smFilter.getValue()).then(res => {

      //     if(res.status == 500){
      //       this.$refs.btnAct.load(false)
      //       this.loadingTb = false
      //       $('#tableShow').hide()

      //       if (res.data.error == 'not_found') {
      //         this.$refs.notif.warning(res.data.message);
      //       }else{
      //         this.generate(object.satker,res.data.message)
      //       }
          
             

              

      //     }else{
      //       this.listDataPaket = res.data
      //       $('#tableShow').fadeIn()
      //       this.$refs.btnAct.load(false)
      //       this.loadingTb = false
      //     }

         

      //   })
      // }

    },
    async generate(id,title) {
      let judul = "Generate Data"
      let subJudul = title

      if (await this.$refs.confirm.open(judul,subJudul) ) {
          let formData = new FormData();
          formData.append('sVersionId',this.$refs.versiSemula.getValue())
          formData.append('mVersionId',this.$refs.versiMenjadi.getValue())
          // formData.append('kdsatker',id)

          this.$refs.confirm.loading()
          // this.$refs.btnActGen.load(true)
          this.G_PostAny('sakti/semula-menjadi/generate', formData).then(res => {
           
              if (res) {
                  this.$refs.confirm.close()
                  this.$snotify.success('Silahkan Cari Ulang Data Satker yang di pilih',res.message);
              }else{
                this.$snotify.warning(res.message);
              }
          })
          .finally(() => {
            // this.$refs.btnActGen.load(false)
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
  }
}